import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import PrintContainer from '../PrintContainer';
import { FlexLayout, Head, Sub } from '../../../elements';
import Content from '../../../components/Content';
import Card from '../../../components/Card';
import AppContainer from '../../../components/AppContainer';
import TableFromJson from '../../../components/TableFromJson';
import AnimatedSVG from '../../../components/AnimatedSvg';

const PositionContainer = styled.div`
  padding: 1rem;
  flex-basis: 100%;
  min-width: 616px;
  max-width: max-content;
  flex: 1;
  flex-basis: 25%;
  margin-right: 1rem;
  margin-bottom: 1rem;
  background-color: ${(props) => props.theme.light};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 695px) {
    min-width: 240px;
  }
  @media screen and (max-width: 420px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const PositionsTable = ({ data }) => {
  const { heading, childContentfulTableTableJsonNode } = data;
  
  return (
    <PositionContainer>
      <Sub>{heading}</Sub>
      <TableFromJson table={childContentfulTableTableJsonNode} />
    </PositionContainer>
  );
};
const PrintRates = ({ data }) => {
  const { contentfulPage: page } = data;

  return (
    <AppContainer>
      <PrintContainer>
        {page.sections.map(section => (
          <section key={section.id}>
            <Head>{section.heading}</Head>
            <Sub>{section.subHeading}</Sub>
            <FlexLayout>
              {section.items
                && section.items.map(item => (
                  <Card key={item.id} item={item} component={AnimatedSVG} />
                ))}
                {section.tables && section.tables.map(table => (
                  <PositionsTable key={table.id} data={table} />
                  ))
                }
                </FlexLayout>
            {section.content && <Content content={section.content.childContentfulRichText.html} />}
          </section>
        ))}
      </PrintContainer>
    </AppContainer>
  );
};

export const printRatesPageQuery = graphql`
         query PrintRatesPageQuery($id: String!) {
           contentfulPage(id: { eq: $id }) {
             title
             sections {
               id
               heading
               subHeading
               content {
                 childContentfulRichText {
                   html
                 }
               }
               items {
                 id
                 label
                 infoList
                 extraInfo
                 component
               }
               tables {
                 id
                 heading
                 childContentfulTableTableJsonNode {
                   internal {
                     content
                   }
                 }
               }
             }
           }
         }
       `;

export default PrintRates;
